import React from 'react'
import FontArchive from './font-archive'

import '../styles/font-archive-list.css'

function FontArchiveList (props) {
  return (
    <div className="font-archive-list wrapper">
    <div>
      {props.title && <h3>{props.title}</h3>}
    </div>
        
      <div className="grid font-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <FontArchive {...node} />
          ))}
      </div>
</div>
  )
}

FontArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default FontArchiveList