import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getFontUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import '../styles/font-archive.css'

function FontArchive (props) {

  //console.log(props);

  return (
        <>
        {props.images[0] && props.images[0].desktopImage.asset && props.images[0].mobileImage.asset && (
          <div className="font-archive-item width-12-12-m width-4-12">
            <div className="image-holder">
              <Link to={getFontUrl(props.slug.current)}>
              <picture>
                <source 
                  media="(max-width: 699px)" 
                  srcset={imageUrlFor(buildImageObj(props.images[0].mobileImage))
                    .width(1000)
                    .height(Math.floor((1 / 1) * 1000))
                    .auto('format')
                    .url()}
                />
                <source 
                  media="(min-width: 700px)" 
                  srcset={imageUrlFor(buildImageObj(props.images[0].desktopImage))
                    .width(2000)
                    .height(Math.floor((1 / 2) * 2000))
                    .auto('format')
                    .url()}
                />
                <img
                  src={imageUrlFor(buildImageObj(props.images[0].desktopImage))
                    .width(2000)
                    .height(Math.floor((1 / 2) * 2000))
                    .auto('format')
                    .url()}
                  alt={props.images[0].alt}
                  title={props.images[0].alt}
                />
              </picture>
              </Link>
            </div>
          </div>
        )}
        </>  
  )
}

export default FontArchive